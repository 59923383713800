import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import * as $ from 'jquery';
import { Utils } from '../../../shared/services/utils.service';
import { QuoteBuildData } from '../quote.build-data';
import { pgDatePickerComponent } from '../../../@pages/components/datepicker/datepicker.component';
import * as moment from 'moment';
import { PermissionService } from '../../../shared/services/permission.service';
import { PermissionGroupService } from '../../../shared/services/permission-group.service';
import { PaginatedSelectComponent } from '../../components/paginated-select/paginated-select.component';
import { QuoteModel } from '../../../shared/models/quote.model';
import { QuoteService } from '../../../shared/services/quote.service';
import { CustomerModel } from '../../../shared/models/customer.model';
import { CustomerService } from '../../../shared/services/customer.service';
import { FurnitureModel } from '../../../shared/models/furniture.model';
import { FurnitureService } from '../../../shared/services/furniture.service';
import { PartService } from '../../../shared/services/part.service';
import { StockService } from '../../../shared/services/stock.service';
import { PartModel } from '../../../shared/models/part.model';
import { StockModel } from '../../../shared/models/stock.model';
import { forget } from '@sentry/utils';
import { PartCategoryService } from '../../../shared/services/part-category.service';
import { PartCategoryModel } from '../../../shared/models/part-category.model';


@Component({
  selector: 'app-quote-detail',
  templateUrl: './quote-detail.component.html',
  styleUrls: ['./quote-detail.component.scss']
})
export class QuoteDetailComponent implements OnInit {

	@Input() external: boolean = false;
	@Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
	object: QuoteModel;
	roles: any[];
	@ViewChild('cloneModal', { static: true }) cloneModal: ModalDirective;
	@ViewChild('deleteModal', { static: true }) modalDelete: ModalDirective;
	@ViewChild('addStockModal', { static: true }) modalAddStock: ModalDirective;
	@ViewChild('editStockModal', { static: true }) modalEditStock: ModalDirective;
	@ViewChild('editPartModal', { static: true }) modalEditPart: ModalDirective;
	@ViewChild('editFurnitureModal', { static: true }) modalFurniturePart: ModalDirective;
	@ViewChild('deleteStockModal', { static: true }) modalDeleteStock: ModalDirective;
	@ViewChild('addPartModal', { static: true }) modalAddPart: ModalDirective;
	@ViewChild('addGroupModal', { static: true }) addGroupModal: ModalDirective;
	@ViewChild('deletePartModal', { static: true }) modalDeletePart: ModalDirective;
	@ViewChild('addFurnitureModal', { static: true }) modalAddFurniture: ModalDirective;
	@ViewChild('deleteFurnitureModal', { static: true }) modalDeleteFurniture: ModalDirective;
	errors: String[] = [];
	data = new QuoteBuildData();
	module = this.data.module;
	form: FormGroup;
	_cus: CustomerModel[] = [];
	_cusr: CustomerModel[] = [];
	_fur: FurnitureModel[] = [];
	_part: PartModel[] = [];
	_stock: StockModel[] = [];
	selectedFurnitureValue: string='';
	selectedPartValue: string='';
	selectedStockValue: string='';
	selectedCustomerValue: string='';
	orderTotalCost = 0;
	orderTotalExclVat = 0;
	orderTotalVat = 0;
	orderTotal = 0;
	urlPdf = '';
	urlRfp = '';
	saveOrderTotal = 0;
	selectedCustomerDetails = new CustomerModel();
	customer = null;
	quote_no = null;
	orderId = null;
	adminPercentage = null;
	profitPercentage = null;
	amountDiscount = 0;
	selectedFurnitureQty = 1;
	furnitureEdit = null;
	furnituresSelected: FormArray;
	groupName = null;
	allGroups = [];
	chosenGroup = null;

	constructor(
	  private service: QuoteService,
	  private partCategoryService: PartCategoryService,
	  private customerService: CustomerService,
	  private furnitureService: FurnitureService,
	  private partService: PartService,
	  private stockService: StockService,
	  private router: Router,
	  private snapshot: ActivatedRoute,
	  private authService: AuthService,
	  private messageService: MessageService,
	  private errorHandler: ErrorHandlingService,
	  private utils: Utils,
	  private cdr : ChangeDetectorRef,
	  public permissionService : PermissionService
	) { }
  
	ngOnInit() {
	  let _this = this;
	  this.createForm({});
	  // this.loadPart();
	  this.loadStock();
	  this.loadPartCat();
	  //this.loadCustomer();
	  this.router.events.subscribe(event => {
		if (event instanceof NavigationStart) {
		  this.messageService.remove();
		}
	  });
	  this.snapshot.paramMap.subscribe(res => {
		if (res['params']['id'] && !_this.external) {
		  this.loadData(res['params']['id']);
		}
	  })
	}
  
	menuItems: any[] = [
	  { action: 'delete', message: 'Delete ' + this.module.name, class: '', icon: 'fal fa-trash', permissions: ['delete_' + this.module.permission] },
	  { action: 'clone', message: 'Clone ' + this.module.name, class: '', icon: 'fal fa-copy', permissions: ['delete_' + this.module.permission]}
	];
  
	handleButtonPress = (event) => {
	  switch (event) {
		case "delete":
		  this.modalDelete.show();
		  break;
		case "clone":
		  this.cloneModal.show();
		  break;
		default:
		  console.log(event);
		  break;
	  }
	}

	get quantity(): any{
		return this.form.get('quantity');
	}

	_partCat: PartCategoryModel[] = [];

	loadPartCat() {
		var _this = this;
		this.partCategoryService.getAll({}).subscribe(res => {
			for (var uObject of res['data']) {
				_this._partCat.push(new PartCategoryModel(uObject));
			}
		});
	}

	createGroup() {
		let rnd =  this.randomString(10);
		const group = {
			order: this.allGroups.length,
			title: this.groupName,
			quantity: 1,
			selling_price: 0,
			image: [],
			id: rnd,
			reference: rnd,
			cost_price: 0,
			line_total: 0,
			items: this.parts,
		};
		this.allGroups.push(group);
		this.parts = [];
		this.groupName = '';
		this.calculatePrice();		
	}

	removePartFromGroup(event) {
		this.allGroups[event.groupIndex].items = this.allGroups[event.groupIndex].items.filter((item, index) => {
			return index != event.partIndex;
		});		
		this.calculatePrice();
	}

	removeGroup(event) {
		if(confirm('⚠️ Are you sure you want to delete this group "' + this.allGroups[event.groupIndex].title +  '"?'))
		{
			this.allGroups.splice(event.groupIndex, 1);
			this.calculatePrice();		
		}
	}
	
	// loadCustomerRef(){
	// 	var _this = this;
	// 	this.customerService.reference('reference_no').subscribe(res => {
	// 		for(var uObject of res['data'])
	// 		{
	// 			_this._cus.push(new CustomerModel(uObject));
	// 		}
	// 	});
	// }


	loadFurniture(){
		var _this = this;
		this.furnitureService.getAll({}).subscribe(res => {
			for(var uObject of res['data'])
			{
				_this._fur.push(new FurnitureModel(uObject));
			}
		});
	}
	loadPart(){
		var _this = this;
		this.partService.getAll({}).subscribe(res => {
			for(var uObject of res['data'])
			{
				_this._part.push(new PartModel(uObject));
			}
		});
	}
	loadStock(){
		var _this = this;
		this.stockService.getAll({}).subscribe(res => {
			for(var uObject of res['data'])
			{
				_this._stock.push(new StockModel(uObject));
			}
		});
	}

	subscription = null;

	onSearchStock(event)
	{
		var _this = this;

		if (_this.partTimeout) {
			clearTimeout(_this.partTimeout);
		}
		
		_this.partTimeout = setTimeout(() => {

			if (_this.subscription) {
				_this.subscription.unsubscribe();
			  }

			_this._stock = [];
			_this.subscription = this.stockService.getAll({per_page: 1000, q: event}).subscribe(res => {
				for(var uObject of res['data'])
				{
					_this._stock.push(new StockModel(uObject));
				}
			});
		}, 350);
	}

	partTimeout = null;

	onSearchPart(event)
	{
		var _this = this;

		if (_this.partTimeout) {
			clearTimeout(_this.partTimeout);
		}

		_this.partTimeout = setTimeout(() => {
	 
			_this._part = [];
			if (_this.subscription) {
				_this.subscription.unsubscribe();
			  }
			_this.subscription = this.partService.getAll({per_page: 1000, q: event, category: _this.selectedPartCategory}).subscribe(res => {
				for(var uObject of res['data'])
				{
					_this._part.push(new PartModel(uObject));
				}
			});
		}, 350);
	}

	customerTimeout = null;
	
	onSearchCustomer(event)
	{
		var _this = this;

		if (_this.customerTimeout) {
			clearTimeout(_this.partTimeout);
		}

		_this.customerTimeout = setTimeout(() => {
	 
			_this._cus = [];
			if (_this.subscription) {
				_this.subscription.unsubscribe();
			  }

			  _this.subscription = this.customerService.getAll({per_page: 1000, q: event}).subscribe(res => {
				for(var uObject of res['data'])
				{
					_this._cus.push(new CustomerModel(uObject));
				}
			});
			// _this.subscription = this.partService.getAll({per_page: 1000, q: event, category: _this.selectedPartCategory}).subscribe(res => {
			// 	for(var uObject of res['data'])
			// 	{
			// 		_this._part.push(new PartModel(uObject));
			// 	}
			// });
		}, 350);
	}
  
	incomingEvent:EventEmitter<any> = new EventEmitter<any>();
	incomingPaginatedSelectValue(fieldName, value) {
	  let _this = this;
	  _this.form.get(fieldName).setValue(value);
	}
  
	markTouched() {
	  this.form.markAsTouched();
	}
  
	imageError: string;
	isImageSaved: boolean;
	cardImageBase64: string;
  
	fileChangeEvent(fileInput: any) {
	  let _this = this;
	  Utils.handleFileChange(fileInput).subscribe(res => {
		let success = res.success;
		if (success) {
		  _this.cardImageBase64 = res.cardImageBase64;
		  _this.isImageSaved = res.isImageSaved;
		}
		else {
		  console.log("Failed to load file.");
		}
	  });
	}
  
	@ViewChild('fromPicker', { static: true }) fromPicker: pgDatePickerComponent;
	@ViewChild('toPicker', { static: true }) toPicker: pgDatePickerComponent;
	fromDate: Date = new Date();
	toDate: Date = moment(new Date()).add(1, 'hour').toDate();
	forceRefresh(dateEvent) {
	  let _this = this;
	  Utils.forceRefresh(dateEvent,this.fromDate,this.toDate,this.fromPicker,this.toPicker).subscribe(res => {
		_this.cdr.detectChanges();
	  })
	}
  
	_disabledStartDate = (startValue) => {
	  return Utils._disabledStartDate(startValue,this.fromDate,this.toDate);
	};
  
	_disabledEndDate = (endValue) => {
	  return Utils._disabledEndDate(endValue,this.fromDate,this.toDate);
	};
  
	removeImage() {
	  this.cardImageBase64 = null;
	  this.isImageSaved = false;
	}
  
	//psitems  :FormArray;
	createForm(input, form?) {
	  input = input || {};
	  form ? form : this.form = QuoteModel.getFormGroup(input);
	  this.furnituresSelected = this.form.get('items') as FormArray;
	}

	selectedPart = null; // this.loadPart();
	selectedFurniture = this.loadFurniture();
	selectedStock = null;
	selectedCustomer = this.loadCustomer();
	//selectedCustomerR = this.test();

	selectCustomerChange(event :any) {
		//this.selectedCustomerValue = this.service.getAll(1)[1].title;
		this.selectedCustomerValue = event.target.value;
		
	}


	loadCustomer(){
		var _this = this;
		this.customerService.getAll(1).subscribe(res => {
			for(var uObject of res['data'])
			{
				_this._cus.push(new CustomerModel(uObject));
			}
		});
	}
	

	AddNewFurniture() {
		var _this = this;
		const objFurniture = _this._fur.filter(furniture => furniture.id == +_this.selectedFurnitureValue)[0];
		this.furnituresSelected.value.push({
			quantity : _this.selectedFurnitureQty,
			selling_price: objFurniture.total_selling,
			image : objFurniture.image,
			title : objFurniture.title,
			id: objFurniture.id,
			cost_price: objFurniture.total_cost,
			reference: this.randomString(10)
		});
		this.calculatePrice();
	}

	selectFurnitureChange(event :any) {
		//this.selectedValue = this.service.get(this.Furnitures)[0].title;
		this.selectedFurnitureValue = event.target.value;
	}
	
	// AddNewStock() {
	// 	var _this = this;
	// 	this.items.push(QuoteModel.getItemsFormGroup({
	// 		quantity : '',
	// 		length : '',
	// 		width : '',
	// 		height : '',
	// 		stock : this.selectedStock
	// 	}));
	// }


	otherItems = [];
	stocks = [];
	parts = [];
	
	AddNewStock() {
		var _this = this;
		this.stocks.push({
			_object : this.selectedStock,
			quantity : this.selectedStockQty,
			reference : this.randomString(10),
			stock : this.selectedStock.id,
			height: this.selectedStockHeight,
			width: this.selectedStockWidth,
			length: this.selectedStockLength,
			title: this.selectedStock.title,
			order: this.stocks.length,
			selling_price: 0
		});
		this.calculatePrice();
		this.modalAddStock.hide();
		// this.items.push(QuoteModel.getItemsFormGroup({
		// 	quantity : this.selectedPartQty,
		// 	part : this.selectedPart.id,
		// 	height: this.selectedPartHeight,
		// 	width: this.selectedPartWidth,
		// 	length: this.selectedPartLength,
		// 	title: this.selectedPart.title,
		// }));
	}

	AddOtherItem() {
		var _this = this;
		this.otherItems.push({
			quantity : 1,
			reference : this.randomString(10),
			title: '',
			selling_price: 0,
			type: 'other'
		});
		this.calculatePrice();
		// this.items.push(QuoteModel.getItemsFormGroup({
		// 	quantity : this.selectedPartQty,
		// 	part : this.selectedPart.id,
		// 	height: this.selectedPartHeight,
		// 	width: this.selectedPartWidth,
		// 	length: this.selectedPartLength,
		// 	title: this.selectedPart.title,
		// }));
	}

	AddNewPart() {
		const newPart = {
			_object : this.selectedPart,
			quantity : this.selectedPartQty,
			reference : this.randomString(10),
			part : this.selectedPart.id,
			height: this.selectedPartHeight,
			width: this.selectedPartWidth,
			length: this.selectedPartLength,
			title: this.selectedPart.title,
			order: this.selectedPart.length,
			selling_price: 0
		};
		if (this.chosenGroup !== null) {
			this.allGroups[this.chosenGroup].items.push(newPart);
			this.chosenGroup = null;
		} else {
			this.parts.push(newPart);
		}
		this.calculatePrice();
		this.modalAddPart.hide();
		
	}

	AddNewEmptyPart() {
		const newPart = {
			_object : null,
			quantity : 1,
			reference : this.randomString(10),
			part : null,
			height: null,
			width: null,
			length: null,
			title: '',
			order: this.allGroups[this.chosenGroup].items.length,
			selling_price: 0
		};
		if (this.chosenGroup !== null) {
			this.allGroups[this.chosenGroup].items.push(newPart);
			this.chosenGroup = null;
		} else {
			this.parts.push(newPart);
		}
		//this.calculatePrice();
		//this.modalAddPart.hide();
		
	}

	calculatePrice() {
		let dItems = [];
		for(let part of this.parts)
		{
			dItems.push(part);
		}

		for(let stock of this.stocks)
		{
			dItems.push(stock);
		}
		for(let oItems of this.otherItems)
		{
			dItems.push(oItems);
		}

		for(let furniture of this.furnituresSelected.value)
		{
			dItems.push({
				...furniture,
				furniture: furniture.id
			});
		}

		for(let group of this.allGroups)
		{
			dItems.push(group);
		}

		let data = {
			customer_reference : this.customer,
			admin_percentage: this.adminPercentage,
			profit_percentage: this.profitPercentage,
			items : dItems,
		};

		let _this = this;
		this.service.calculatePrice(data).subscribe(res => {
			const temporaryGroups = this.allGroups;
			_this.orderTotal = res['data']['final_price'];
			_this.saveOrderTotal = _this.orderTotal;
			_this.orderTotalCost = res['data']['total_cost'];
			_this.orderTotalVat = res['data']['total_vat'];
			_this.orderTotalExclVat = res['data']['total_excl_vat'];

			for(let part of res['data']['parts'])
			{
				if (part.items !== undefined) {
					this.findItemsData(part, temporaryGroups);
				}
				for(let lPartIdx in _this.parts)
				{
					if(_this.parts[lPartIdx].reference == part['reference'])
					{
					 	_this.parts[lPartIdx].cost_price = part['total_cost'];
					 	_this.parts[lPartIdx].total_excl_vat = part['total_excl_vat'];
					 	_this.parts[lPartIdx].selling_price = part['final_price'];
					 	_this.parts[lPartIdx].line_total = part['total_excl_vat'] * part['quantity'];
						break;
					}
				}
			}

			for(let stock of res['data']['stocks'])
			{
				for(let lStockIdx in _this.stocks)
				{
					if(_this.stocks[lStockIdx].reference == stock['reference'])
					{
					 	_this.stocks[lStockIdx].cost_price = stock['total_cost'];
					 	// _this.stocks[lStockIdx].total_excl_vat = stock['total_excl_vat'];
					 	_this.stocks[lStockIdx].selling_price = stock['total_excl_vat'];
					 	_this.stocks[lStockIdx].line_total = stock['total_excl_vat'] * stock['quantity'];
						break;
					}
				}
			}

			for(let furniture of res['data']['furniture']) {
				for(let lFurnitureIdx in _this.furnituresSelected.value)
				{
					if(_this.furnituresSelected.value[lFurnitureIdx].reference == furniture['reference'])
					{
					 	_this.furnituresSelected.value[lFurnitureIdx].cost_price = furniture['total_cost'];
					 	_this.furnituresSelected.value[lFurnitureIdx].selling_price = furniture['total_excl_vat'];
					 	_this.furnituresSelected.value[lFurnitureIdx].line_total = furniture['total_excl_vat'] * furniture['quantity'];
						break;
					}
				}
			}


		},
		err => {
			console.log('error: ', err);
		});
	}

	findItemsData(part, temporaryGroups) {
		this.allGroups = [];
		// debugger;
		temporaryGroups.forEach((elementPart, indexPart) => {
			if (elementPart.reference == part.reference) {
				
				temporaryGroups[indexPart] = {
					...temporaryGroups[indexPart],
					...part,
					items: part.items.length > 0 ? [
						...temporaryGroups[indexPart].items.map( item => {
							const objFromCalculate = part.items.filter((element, index) => (item.reference == element.reference))[0];

							if(objFromCalculate == null)
							{
								debugger;
								return {
									...item,
									line_total: -1,
									cost_price: -1,
									...objFromCalculate
								}
							}

							return {
								...item,
								line_total: objFromCalculate.total_excl_vat * objFromCalculate.quantity,
								cost_price:objFromCalculate.total_cost,
								...objFromCalculate
							}
						})
					] : []
				}
			}
		});
		this.allGroups = [
			...temporaryGroups
		];
	}

	removePart(idx)
	{
		this.parts.splice(idx, 1);
		this.calculatePrice();
	}

	removeStock(idx)
	{
		this.stocks.splice(idx, 1);
		this.calculatePrice();
	}
	removeOtherItem(idx)
	{
		this.otherItems.splice(idx, 1);
		this.calculatePrice();
	}

	stockEdit = null;
	stockEditIdx = -1;

	editStock(idx)
	{
		this.stockEditIdx = idx;
		this.stockEdit = this.stocks[idx];
		this.modalEditStock.show();
	}

	updateStock()
	{
		this.stocks[this.stockEditIdx] = this.stockEdit;
		this.modalEditStock.hide();
		this.calculatePrice();
	}

	partEdit = null;
	partEditIdx = -1;

	editPart(idx)
	{
		this.partEditIdx = idx;
		this.partEdit = this.parts[idx];
		this.modalEditPart.show();
	}

	setFurnitureToEdit(idx) {
		this.furnitureEdit = {...this.furnituresSelected.value[idx]};
		this.modalFurniturePart.show();
	}

	updatePart()
	{
		this.parts[this.partEditIdx] = this.partEdit;
		this.modalEditPart.hide();
		this.calculatePrice();
	}

	updateFurniture()
	{
		this.furnituresSelected.value.map(furniture => (furniture.id == this.furnitureEdit.id) ? furniture.quantity = +this.furnitureEdit.quantity: furniture);
		this.furnitureEdit = null;
		this.calculatePrice();
		this.modalFurniturePart.hide();
	}

	selectedPartHeight = null;
	selectedPartWidth = null;
	selectedPartLength = null;
	selectedPartQty = 1;

	selectPartChange(event :any) {
		//this.selectedValue = this.service.get(this.parts)[0].title;
		for(let p of this._part )
		{
			if(p.id == event)
			{
				this.selectedPart = p;
				this.selectedPartHeight = p.height;
				this.selectedPartWidth = p.width;
				this.selectedPartLength = p.length;
				this.selectedPartQty = 1;
				break;
			}
		}
		// this.selectedPart = event.target.value;
	}

	selectedPartCategory = null;

	selectPartCatChange(event :any) {
		//this.selectedValue = this.service.get(this.parts)[0].title;
		var _this = this;

		if (_this.partTimeout) {
			clearTimeout(_this.partTimeout);
		}
		this.selectedPartCategory = event;
		_this.partTimeout = setTimeout(() => {
	 
			_this._part = [];
			if (_this.subscription) {
				_this.subscription.unsubscribe();
			  }
			_this.subscription = this.partService.getAll({per_page: 1000, q: event, category: event}).subscribe(res => {
				for(var uObject of res['data'])
				{
					_this._part.push(new PartModel(uObject));
				}
			});
		}, 350);
		// this.selectedPart = event.target.value;
	}

	selectedStockHeight = null;
	selectedStockWidth = null;
	selectedStockLength = null;
	selectedStockQty = 1;

	selectStockChange(event :any) {
		//this.selectedValue = this.service.get(this.parts)[0].title;
		for(let s of this._stock )
		{
			if(s.id == event)
			{
				this.selectedStock = s;
				this.selectedStockHeight = 0;
				this.selectedStockWidth = 0;
				this.selectedStockLength = 0;
				this.selectedStockQty = 1;
				break;
			}
		}
		// this.selectedPart = event.target.value;
	}

	addPartToGroup(dataGroup) {
		this.chosenGroup = dataGroup.groupIndex;
		this.modalAddPart.show();
	}

	addEmptyPartToGroup(dataGroup) {
		this.chosenGroup = dataGroup.groupIndex;
		this.AddNewEmptyPart();
	}
  
	cloneGroup(dataGroup) {

		if(confirm('Are you sure you want to clone this gorup?'))
		{
			let group = JSON.parse(JSON.stringify(this.allGroups[dataGroup.groupIndex]));

			group.id = null;
			group.reference =  this.randomString(10);
			group.title = "Clone -" + group.title;

			for(let i = 0; i < group.items.length; i++)
			{
				group.items[i].id = null;
				group.items[i].reference =  this.randomString(10);
			}
	
			this.allGroups.push(group);
			this.chosenGroup = null;
			this.calculatePrice();		
		}
	}
  

	openFileUpload(ele) {
	  $(ele).click();
	}
  
	hasPermission(permission) {
	  return this.authService.hasPermission(permission);
	}
  
	isTouched(controlName) {
	  return Utils.isFormTouched(this.form, controlName);
	}
  
	loading = {
	  isFirstLoad: false,
	  isLoading: false
	};
  
	loadData(id) {
	  let _this = this;
	  let checkLoadingSpinner = () => {
		if (!_this.loading.isFirstLoad) {
		  _this.loading.isFirstLoad = true;
		  _this.loading.isLoading = true;
		}
	  }
  
	  checkLoadingSpinner();
  
	  this.service.get(id).subscribe(res => {
		//   _this.orderTotalCost = res['data']['total_cost'];
		_this.orderTotal = res['data']['total_price'];
		_this.urlPdf = res['data']['pdf_url'];
		_this.urlRfp = res['data']['rfp_url'];
		_this.orderTotalVat = res['data']['vat_total'];
		_this.orderTotalExclVat = res['data']['sub_total'];

		_this.object = res['data'];
		_this.orderId = res['data']['id'];
		_this.quote_no = res['data']['quote_no'];

		_this.adminPercentage = res['data']['admin_percentage'];
		_this.profitPercentage = res['data']['profit_percentage'];

		_this.customer = _this.object['customer']['reference_no'];
		_this.selectedCustomerDetails = new CustomerModel(_this.object['customer']);

		for(let p of _this.object['items'])
		{
			p.order = p.order;
			if(p.part != null)
			{
				p._object = p.part;
				p.part = p._object.id;
				p.reference = this.randomString(10);
				p.title = p._object.title;
				_this.parts.push(p);
			}
			else if (p.stock != null)
			{
				p.title = p.stock.title;
				p._object = p.stock;
				
				p.stock = p._object.id;
				p.reference = this.randomString(10);

				_this.stocks.push(p);
			}
			else if (p.furniture != null)
			{
				p.title = p.furniture.title;
				p.image = p.furniture.image;
				p._object = p.furniture;
				p.furniture = p._object.id;
				p.id = p._object.id;
				_this.furnituresSelected.value.push(p);
			 } else if (p.type == 'other')
			 {
				 _this.otherItems.push(p);
			 } else if ('items' in p && p['items'] !== null && p['items'].length > 0) {
			 	_this.allGroups.push(p);
			 }
		}

		// _this.createForm(_this.object);
		// _this.triggerFetch.emit(_this.form);
		_this.loading.isLoading = false;
	  },
		err => {
		  _this.loading.isLoading = false;
		  console.log(err);
		});
	}
  
	//Variable & Function for <phone-input>
	triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	updateForm(event) {
	  this.form = event;
	}
  
	delete() {
	  let _this = this;
	  _this.isLoading = true;
	  this.utils.delete(this.service, this.object.id, this.module.name, "/"+this.module.new_name).subscribe(res => {
		_this.isLoading = false;
	  });
	}
  
	clone() {
	  let _this = this;
	  _this.isLoading = true;
	  this.service.clone(this.orderId).subscribe(res => {
		if (res['data']) {
			this.router.navigate(['/'+this.module.new_name+'/' + res['data']['id']])
			.then(() => {
				window.location.reload();
			  });
		}
		_this.selectedCustomerDetails = new CustomerModel(res['data']['customer']);
		_this.cloneModal.hide();
		_this.utils.successMessage('Order cloned.');
		_this.isLoading = false;
	});
	}
  
	isFormValueChecked(controlName) {
	  return this.form.get(controlName).value;
	}
  
	photoChangeEvent(fileInput: any) {
	  let _this = this;
	  Utils.handleFileChange(fileInput).subscribe(res => {
		let success = res.success;
		if (success) {
		  _this.cardImageBase64 = res.cardImageBase64;
		  _this.isImageSaved = res.isImageSaved;
		  _this.form.get('image').setValue(res.cardImageBase64);
		}
		else {
		  console.log("Failed to load file.");
		}
	  });
	}
  
	@Input() isLoading: boolean = false;

	changeFurnitureOrder(val, idx) {
		this.furnituresSelected.value[idx].order = val;
	}

	changePartOrder(val, idx) {
		this.parts[idx].order = val;
	}
	changeStockOrder(val, idx) {
		this.stocks[idx].order = val;
	}

	getMaxOrder(maxOrder)
	{
		return Array(maxOrder).fill(0).map((x,i)=>i);
	}
  
  
	@ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];
	save() {
	  let _this = this;
	  _this.isLoading = true;

	  debugger;
  
	//   if(this.fromDate){
	// 	_this.form.get("valid_from").setValue(moment(this.fromDate).format());
	//   }
  
	//   if(this.toDate){
	// 	_this.form.get("valid_to").setValue(moment(this.fromDate).format());
	//   }

	let dataItems = [];

	for(var s of _this.stocks)
	{
		dataItems.push(s);
	}

	for(var s of _this.otherItems)
	{
		dataItems.push(s);
	}
	for(let furniture of this.furnituresSelected.value)
	{
		dataItems.push({
			...furniture,
			furniture: furniture.id
		});
	}

	for(var group of _this.allGroups)
	{
		if (this.orderId !== null) {
			for(let part of group.items)
			{
				if(part.part != null && part.part.id) {
					part.part = part.part.id;
				}
			}
		}
		dataItems.push(group);
	}
		
	let data = {
		customer_reference: _this.customer,
		discount_amount: null,
		discount_percentage: null,
		admin_percentage: _this.adminPercentage,
		profit_percentage: _this.profitPercentage,
		items: dataItems
	};


	if(this.orderId !== null)
	{
		this.service.edit(this.orderId, data).subscribe(res => {
			if (res['data']) {
				this.router.navigate(['/'+this.module.new_name+'/' + res['data']['id']]);
			}
			_this.selectedCustomerDetails = new CustomerModel(res['data']['customer']);
			
			_this.utils.successMessage('Order saved.');
			_this.isLoading = false;
		});
	} else {
		this.service.create(data).subscribe(res => {
			if (res['data']) {
				this.router.navigate(['/'+this.module.new_name+'/' + res['data']['id']]);
			}
			
			_this.selectedCustomerDetails = new CustomerModel(res['data']['customer']);

			_this.utils.successMessage('Order saved.');
			_this.isLoading = false;
		});
	}


  
	//   Utils.forceTouchPaginatedSelects(_this.paginatedSelects);
	//   Utils.handleFormSave(this.form, this.service, this.object, this.module.name, this.createForm, null).subscribe(res => {
	// 	_this.isLoading = false;
	// 	if (res['data']) {
	// 	  if (_this.external == true) {
	// 		_this.saveEvent.emit(res['data']);
	// 	  }
	// 	  else {
	// 		this.router.navigate(['/'+this.module.new_name+'/' + res['data']['id']]);
	// 	  }
	// 	  _this.utils.successMessage(_this.module.name+' saved.');
	// 	}
	// 	else{
	// 	  Utils.sendFormSaveError(res,this.module,this.utils);
	// 	}
	//   })
	}
	
	randomString(length) {
		var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var result = '';
		for ( var i = 0; i < length; i++ ) {
			result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
		}
		return result;
	}

	applyDiscount(eventData) {
		(eventData >= 0 && this.orderTotal > 0 && eventData < this.saveOrderTotal) ? this.orderTotal = this.saveOrderTotal - eventData : null;
	}

	removeFurniture(index) {
		this.furnituresSelected.value.splice(index, 1);
		this.calculatePrice();
	}

	updateNameGroup(event) {
		this.allGroups[event.groupIndex].title = event.name;
	}
	updateGroupOrder(event) {
		this.allGroups[event.groupIndex].order = event.order;
	}

	updateQuantityPart(data) {
		this.allGroups[data.groupIndex].items[data.partIndex].quantity = +data.quantity;
		this.calculatePrice();
	}
	updateGroupQuantity(data) {
		this.allGroups[data.groupIndex].quantity = +data.quantity;
		this.calculatePrice();
	}
  }
  